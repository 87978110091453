import { SAVE_CONVERSATION, CLOSE_CONVERSATION, CLEAR_CONVERSATION } from "../constants/actionCreators";

const initialState = {}

export default (store, action) => {
  if (action.type === SAVE_CONVERSATION) {
    store = action.context;
  }

  if (action.type === CLOSE_CONVERSATION || action.type === CLEAR_CONVERSATION) {
    store = {};
  }
  
  return store || initialState;
};