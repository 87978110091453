import {
  TOGGLE_CHAT,
  TOGGLE_MODAL,
  TOGGLE_MODAL_IMAGE,
  TOGGLE_MINIMIZE,
  TOGGLE_OPTIONS,
  TOGGLE_LOADING,
  INIT_CONVERSATION,
  CLOSE_CONVERSATION,
  CLEAR_CONVERSATION,
  TOGGLE_WEBSOCKET,
  TOGGLE_SPEECH_TO_TEXT,
  TOGGLE_TEXT_TO_SPEECH,
  LOADED_TEXT_TO_SPEECH_SOUND,
  COUNTER,
  TOGGLE_ATTACH_LOADING,
  TOGGLE_CLIENT_INPUT,
  TOGGLE_SEND_MSG_TO_OPERATOR,
  TOGGLE_SEND_MSG_TO_OPERATOR_SERVICENOW,
  TOGGLE_MSG_SOUND,
  TOGGLE_ATTACH_AVAILABLE,
  TOGGLE_ALLOW_ATTACHMENT,
} from "../constants/actionCreators";

import RateService from '../services/rate';
import ChatSession from "../helpers/chatSession";
import RequestHelper from "../helpers/requestHelper";

export const toggleChat = (opened) => ({
  type: TOGGLE_CHAT,
  opened
});

export const toggleLoading = (loading) => ({
  type: TOGGLE_LOADING,
  loading
});

export const toggleAttachLoad = status => ({
  type: TOGGLE_ATTACH_LOADING,
  status
});

export const toggleAttachAvailable = status => ({
  type: TOGGLE_ATTACH_AVAILABLE,
  status
});

export const toggleAllowAttachment = status => ({
  type: TOGGLE_ALLOW_ATTACHMENT,
  status
});

export const toggleMinimize = (minimize) => ({
  type: TOGGLE_MINIMIZE,
  minimize
});

export const initConversation = (config) => ({
  type: INIT_CONVERSATION,
  config
});

export const closeConversation = () => {
  /**
   * O TRATAMENTO DE FECHAMENTO DO CHAT SERÁ FEITO PELO SOCKET
   * Zava (19/06/2020)
   */
  return { type: CLOSE_CONVERSATION }
};

export const clearConversation = () => ({
  type: CLEAR_CONVERSATION
});

export const toggleWebsocket = (toggle) => {
  if (!toggle) ChatSession.endSession();
  return {
    type: TOGGLE_WEBSOCKET,
    toggle
  }
};
export const toggleClientInput = (toggle) => ({
  type: TOGGLE_CLIENT_INPUT,
  toggle
});

export const toggleWebsocketMgsToOperator = (toggle) => ({
  type: TOGGLE_SEND_MSG_TO_OPERATOR,
  toggle
});

export const toggleWebsocketMgsToOperatorServiceNow = (toggle) => ({
  type: TOGGLE_SEND_MSG_TO_OPERATOR_SERVICENOW,
  toggle
});

export const toggleOptions = toggle => ({
  type: TOGGLE_OPTIONS,
  toggle
});

export const toggleSttActive = toggle => ({
  type: TOGGLE_SPEECH_TO_TEXT,
  toggle
});
//
export const toggleMsgSound = toggle => ({
  type: TOGGLE_MSG_SOUND,
  toggle,
});

export const toggleTtsActive = toggle => ({
  type: TOGGLE_TEXT_TO_SPEECH,
  toggle,
});

export const loadedTTSSound = sound => ({
  type: LOADED_TEXT_TO_SPEECH_SOUND,
  sound
});

export const toggleModal = (toggle, message, onConfirm, onClose) => {

  if (!onClose) onClose = onConfirm;

  const payload = {
    status: toggle,
    message,
    onConfirm,
    onClose,
  }

  return {
    type: TOGGLE_MODAL,
    payload,
  }
};

export const sendEvaluation = (form, qtdFields, score) => dispatch => {
  const rate = new RateService();
  score = parseInt(score, 10) + 1;
  qtdFields = parseInt(qtdFields, 10);

  const evaluation = score / qtdFields;

  const newForm = {
    conversation_id: form.conversation_id,
    serviceEvaluation: {
      quantityFields: qtdFields,
      valueEvaluation: evaluation,
      endMessage: form.endMessage,
      startMessage: form.startMessage
    }
  }

  return rate.sendEvaluation(newForm)
    .then(RequestHelper.handleResponse)
    .catch(err => {
      console.log(err);
      console.log("Erro:", err.statusText);
    });
}

export const setCounter = counter => ({
  type: COUNTER,
  counter
})

export const toggleModalImage = (toggle, link, imageName, sent) => {

  const payload = {
    status: toggle,
    link,
    imageName,
    sent
  }

  return {
    type: TOGGLE_MODAL_IMAGE,
    payload,
  }
};
