import "react-app-polyfill/ie11";
import "abortcontroller-polyfill/dist/polyfill-patch-fetch";
import "md-gum-polyfill";
import "custom-event-polyfill";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import getEnvironment from "./constants/env";
import "./index.scss";

export const initChat = (elementId) => {
  const container = createContainer(elementId);
  loadAssets( (assets) => {
    if (container) {
      ReactDOM.render(<App />, container);
    }
    createStyles(assets);
  });
};

const createContainer = (elementId) => {
  const container = document.createElement("div")
  container
    .setAttribute("id", elementId);
  document.body.appendChild(container);
  return container;
}

const createStyles = (assets) => {
  if (!assets) {
    return false;
  }
  const originatedUrl = getOriginatedUrl();
  const styles = document.createElement("link");
  styles.setAttribute("href", `${originatedUrl.url}/${assets["main.css"]}`);
  styles.setAttribute("rel", "stylesheet")
  document.head.appendChild(styles);
  return styles;
};

const loadAssets = (cbFn) => {
  const environment = getEnvironment();
  if (environment.env === "production") {
    return getAssets()
      .then( res => !res.ok ? Promise.reject(res) : res.json())
      .then( (response) => {
        const assets = response;
        if (cbFn) cbFn(assets);
      })
  }
  if (cbFn) {
    cbFn();
  };
};

const parseUrl = (url) => {
  const parser = document.createElement("a");
  let searchObject = {}, queries, split;
  parser.href = url;
  queries = parser.search.replace(/^\?/, '').split('&');
  queries.forEach( query => {
    split = query.split("=");
    searchObject[split[0]] = split[1];
  });
  return {
    protocol: parser.protocol,
    host: parser.host,
    hostname: parser.hostname,
    port: parser.port,
    pathname: parser.pathname,
    search: parser.search,
    searchObject: searchObject,
    hash: parser.hash
  }; 
};

const getOriginatedUrl = () => {
  let url = "";
  let parsedUrl = {};
  const script = document.getElementById("omni-chat-snippet");
  if (script) {
    parsedUrl = parseUrl(script.src);
    url = `${parsedUrl.protocol}//${parsedUrl.host}`;
  }
  return { url, parsedUrl };
};

const getAssets = () => {
  // const originatedUrl = getOriginatedUrl();
  return fetch(`${process.env.REACT_APP_SNIPPET_URL}/asset-manifest.json`, {mode: "cors"});
}
