import ConfigService from "../services/config";
import RequestHelper from "../helpers/requestHelper";

import { STORE_CONFIG } from "../constants/actionCreators";

export const loadConfig = () => dispatch => {
  const promise = new ConfigService().getConfig()
  promise
    .then(RequestHelper.handleResponse)
    .then( res => {
      dispatch(storeConfig(res))
    })
    .catch(err => {
      console.error(err);
    })
};

export const storeConfig = (config) => ({
  type: STORE_CONFIG,
  config
});