import { store } from "../App";
import clearLocalStorage from "./clearLocalStorage";
class ChatSessionHelper {
  static createChatSession() {
    localStorage.setItem("session-messages", "{}");
    localStorage.setItem("session-unread-messages", "[]");
    localStorage.setItem("session-config", "{}");
    localStorage.setItem("last-activity", Date.now());
  }

  static checkSession() {
    if (localStorage.getItem("session-messages")) return true;
    return false;
  }

  static getSessionData() {
    const localConversation = JSON.parse(localStorage.getItem('session-messages'));
    const localConfig = JSON.parse(localStorage.getItem('session-config'));
    const isCustomerSendMessageToOperator = JSON.parse(localStorage.getItem('isCustomerSendMessageToOperator'));
    const isCustomerSendMessageToServiceNow = JSON.parse(localStorage.getItem('isCustomerSendMessageToServiceNow'));

    return { conversation: localConversation, config: localConfig, isCustomerSendMessageToOperator, isCustomerSendMessageToServiceNow };
  }

  static updateMessages(messages, configs) {
    if (!store.getState().app.websocket) return false;
    localStorage.setItem("session-messages", JSON.stringify(messages));
    localStorage.setItem("session-config", JSON.stringify(configs));
    //console.log('config:' + JSON.stringify(configs))
    //console.log('messages:' + JSON.stringify(messages))
  }

  static endSession() {
    clearLocalStorage();
  }
}

export default ChatSessionHelper;
