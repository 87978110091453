import "isomorphic-fetch";
import es6Promise from "es6-promise";

import getEnvironment from "../constants/env";

es6Promise.polyfill();

class AppServices {

  config = getEnvironment()

  HEADERS = new Headers({
    "Accept-Encoding": "deflate , gzip",
    "Accept": "application/json",
    "Content-Type": "application/json"
  });

  request(url, method, body = null, headers = null, options = {}) {
    let requestBody = body;
    if (headers && !headers instanceof Headers) {
      throw new Error("You have use an instance of Headers.");
    }
    if (body) {
      requestBody = JSON.stringify(body)
    }
    if (body instanceof FormData) {
      requestBody = body;
    }
    let permanentHeader = headers || this.HEADERS
    Object.assign(permanentHeader, {
      "Accept-encoding": "deflate,gzip"
    })

    return fetch(`${this.config.REACT_APP_ENDPOINT_URL}${url}`, {
      method,
      mode: "cors",
      headers: headers || this.HEADERS,
      body: requestBody,
      ...options
    });
  }
}

export default AppServices;