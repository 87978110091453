import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { XMarkIcon, DownloadIcon } from '../../assets/fontawesome'

import { toggleModalImage } from '../../actions/app';

import './modal-image.scss';

class ModalImage extends PureComponent {

  state = {
    type: '',
    disabled: true,
  }

  constructor(props) {
    super(props);
    this.getModal = this.getModal.bind(this);
  }

  handleClose = () => {
    this.props.toggleModalImage(false, '', '', false);
  }

  handleDownload = async () => {
    try {
      const image = await fetch(this.props.modalImage.link);
      const fileName = this.props.modalImage.imageName
      const imageBlog = await image.blob()
      const imageURL = URL.createObjectURL(imageBlog)
      const link = document.createElement('a')
      link.href = imageURL;
      link.download = this.props.modalImage.sent ? `${fileName}.jpg` : fileName;
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
    catch (e) {
      console.log(e)
    }
  }

  getModal() {
    return (
      <div className='Modal-container-image' onClick={this.handleClose}>
        <div className='box' onClick={e => e.stopPropagation()}>
          <div className='action-buttons'>
            <button alt="Download" title="Download" onClick={() => this.handleDownload()}>
              <DownloadIcon color='white' width="25px" />
            </button>
            <button alt="Fechar" title="Fechar" onClick={this.handleClose}>
              <XMarkIcon color='white' width="25px" />
            </button>
          </div>
          <div className='image-content'>
            <img alt="" src={this.props.modalImage.link} />
            <span>{this.props.modalImage.imageName}</span>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return this.props.modalImage.status ? this.getModal() : false;
  }
}

const mapStateToProps = state => ({
  modalImage: state.app.modalImage,
});

const mapDispatchToProps = dispatch => ({
  toggleModalImage: (toggle, link, fileName, sent) => dispatch(toggleModalImage(toggle, link, fileName, sent))
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalImage);