import { SEND_MESSAGE, CLOSE_CONVERSATION, SET_CONVERSATION } from "../constants/actionCreators";

const initialState = []

export default (store, action) => {
  if (action.type === SET_CONVERSATION) {
    store = action.conversation;
  }
  if (action.type === SEND_MESSAGE) {
    store = [...store, action.message];
  }
  if (action.type === CLOSE_CONVERSATION) {
    store = [];
  }
  return store || initialState;
};