import { CHANGE_TO_LIGHT, CHANGE_TO_DARK } from "../constants/actionCreators";

const initialState = 'light'

export default (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_TO_LIGHT:
            return 'light';
        case CHANGE_TO_DARK:
            return 'dark';
        default:
            return state;
    }
};