export { AngleDownIcon } from './svgs/AngleDownIcon'
export { AngleUpIcon } from './svgs/AngleUpIcon'
export { CommentsIcon } from './svgs/CommentsIcon'
export { DownloadIcon } from './svgs/DownloadIcon'
export { MicrophoneAltSlashIcon } from './svgs/MicrophoneAltSlashIcon'
export { MicrophoneIcon } from './svgs/MicrophoneIcon'
export { PaperClipIcon } from './svgs/PaperClipIcon'
export { PaperPlaneIcon } from './svgs/PaperPlaneIcon'
export { PrintIcon } from './svgs/PrintIcon'
export { SpinnerIcon } from './svgs/SpinnerIcon'
export { ThumbsDownIcon } from './svgs/ThumbsDownIcon'
export { ThumbsUpIcon } from './svgs/ThumbsUpIcon'
export { VolumeHighIcon } from './svgs/VolumeHighIcon'
export { VolumeOffIcon } from './svgs/VolumeOffIcon'
export { XMarkIcon } from './svgs/XMarkIcon'
export { CircleHalfStroke } from './svgs/CircleHalfStroke'