class RequestHelper {
  static handleResponse(res) {
    if (!res.ok) {
      return Promise.reject(res);
    }
    return res.json();
  }

  static handleResponseArray(res) {
    return Promise.all(res.map( response => response.json()));
  }
}

export default RequestHelper;