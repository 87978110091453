class CommonHelper {
    static cloneArray(array) {
      return JSON.parse(JSON.stringify(array))
    }

    static isEmpty(value) {
      return  value === undefined || 
              value === null ||
              (typeof value === "object" && Object.keys(value).length === 0) ||
              (typeof value === "string" && value.trim().length === 0)
    }
}

export default CommonHelper;