import {
  TOGGLE_CHAT,
  TOGGLE_MODAL,
  TOGGLE_MODAL_IMAGE,
  TOGGLE_MINIMIZE,
  TOGGLE_OPTIONS,
  TOGGLE_LOADING,
  INIT_CONVERSATION,
  CLOSE_CONVERSATION,
  STORE_CONFIG,
  TOGGLE_WEBSOCKET,
  TOGGLE_SPEECH_TO_TEXT,
  TOGGLE_TEXT_TO_SPEECH,
  LOADED_TEXT_TO_SPEECH_SOUND,
  COUNTER,
  TOGGLE_ATTACH_LOADING,
  TOGGLE_ATTACH_AVAILABLE,
  TOGGLE_ALLOW_ATTACHMENT,
  TOGGLE_CLIENT_INPUT,
  TOGGLE_SEND_MSG_TO_OPERATOR,
  TOGGLE_SEND_MSG_TO_OPERATOR_SERVICENOW,
  TOGGLE_MSG_SOUND
} from "../constants/actionCreators";
import CommonHelper from "../helpers/common"

const initialState = {
  configLoaded: false,
  opened: false,
  conversation: false,
  minimize: false,
  loading: false,
  websocket: false,
  options: false,
  ttsActive: false,
  msgSoundActive: true,
  sttActive: false,
  loadedTtsSound: false,
  counter: 0,
  attachLoading: false,
  attachAvailable: false,
  allowAttachment: false,
  inputActive: true,
  isCustomerSendMessageToOperator: false,
  isCustomerSendMessageToOperatorServiceNow: false,

  modal: {
    status: false,
    message: '',
    onConfirm: () => { },
    onClose: () => { },
  },
  modalImage: {
    status: false,
    link: '',
    fileName: '',
    sent: false,
    onConfirm: {},
    onClose: {},
  }
};

export default (store, action) => {

  if (action.type === STORE_CONFIG) {
    return {
      ...store,
      configLoaded: !CommonHelper.isEmpty(action.config)
    };
  }

  if (action.type === TOGGLE_LOADING) {
    return {
      ...store,
      loading: action.loading
    };
  }

  if (action.type === COUNTER) {
    return {
      ...store,
      counter: action.counter
    };
  }

  if (action.type === TOGGLE_MODAL) {
    return {
      ...store,
      modal: {
        ...action.payload
      }
    };
  }

  if (action.type === TOGGLE_CHAT) {
    return {
      ...store,
      opened: action.opened
    };
  }

  if (action.type === TOGGLE_OPTIONS) {
    return {
      ...store,
      options: action.toggle
    }
  }

  if (action.type === TOGGLE_WEBSOCKET) {
    return {
      ...store,
      websocket: action.toggle
    }
  }

  if (action.type === TOGGLE_MINIMIZE) {
    return {
      ...store,
      minimize: action.minimize
    };
  }

  if (action.type === TOGGLE_ATTACH_LOADING) {
    return {
      ...store,
      attachLoading: action.status
    };
  }

  if (action.type === TOGGLE_ATTACH_AVAILABLE) {
    return {
      ...store,
      attachAvailable: action.status
    };
  }

  if (action.type === TOGGLE_ALLOW_ATTACHMENT) {
    return {
      ...store,
      allowAttachment: action.status
    };
  }

  if (action.type === TOGGLE_MSG_SOUND) {
    return {
      ...store,
      msgSoundActive: action.toggle
    }
  }

  if (action.type === TOGGLE_SPEECH_TO_TEXT) {
    return {
      ...store,
      sttActive: action.toggle
    }
  }

  if (action.type === TOGGLE_TEXT_TO_SPEECH) {
    return {
      ...store,
      ttsActive: action.toggle
    }
  }

  if (action.type === TOGGLE_MODAL_IMAGE) {
    return {
      ...store,
      modalImage: {
        ...action.payload
      }
    }
  }

  if (action.type === LOADED_TEXT_TO_SPEECH_SOUND) {
    return {
      ...store,
      loadedTtsSound: action.sound,
    }
  }

  if (action.type === INIT_CONVERSATION) {
    return {
      ...store,
      conversation: true,
      config: action.config,
    }
  }

  if (action.type === CLOSE_CONVERSATION) {
    return {
      ...store,
      conversation: false
    }
  }
  if (action.type === TOGGLE_CLIENT_INPUT) {
    return {
      ...store,
      inputActive: action.toggle
    }
  }

  if (action.type === TOGGLE_SEND_MSG_TO_OPERATOR) {
    return {
      ...store,
      isCustomerSendMessageToOperator: action.toggle
    };
  }

  if (action.type === TOGGLE_SEND_MSG_TO_OPERATOR_SERVICENOW) {
    return {
      ...store,
      isCustomerSendMessageToOperatorServiceNow: action.toggle
    };
  }

  return store || initialState;
};
