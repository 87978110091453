import React, { Component, Suspense } from "react";
import { connect } from "react-redux";
import { loadConfig } from "../actions/config";
import ChatSession from "../helpers/chatSession";
import { toggleChat, initConversation, toggleWebsocket } from "../actions/app";
import UserInfo from "../services/user-info";
import ModalImage from "../components/modal-image/modal-image";

const Chat = React.lazy(() => import('../components/chat/chat'));
const ChatButtonDesktop = React.lazy(() => import('../components/chat/chat-button-desktop'));
const ChatButtonMobile = React.lazy(() => import('../components/chat/chat-button-mobile'));
const Modal = React.lazy(() => import('../components/modal/modal'));

class AppContainer extends Component {
  userInfo = UserInfo.getInfo();

  constructor(props) {
    super(props);
    this.props.loadConfig();
  }

  componentDidMount() {
    this.setupApp();
  }

  setupApp() {
    const interval = setInterval(() => {
      if (this.props.configLoaded) {
        if (ChatSession.checkSession()) {
          console.debug("Já tinha sessao aberta!");

          const lastActivity = localStorage.getItem("last-activity");
          const chatTimeoutMs = this.props.config.chatTimeoutMs || 300000;

          if (lastActivity && Date.now() - lastActivity * 1 < chatTimeoutMs) {
            this.props.toggleChat();
            this.props.toggleWebsocket();
            this.props.initConversation();
          } else {
            localStorage.clear();
          }
        }
        clearInterval(interval);
      }
    }, 100);
  }

  render() {
    const isMobile = this.userInfo.isMobile;
    if (!this.props.configLoaded) return null;
    return (
      <div className={`chat-web-container theme--${this.props.theme}`}>
        <Suspense fallback={() => {}}>
          {this.props.opened ? (
            <Chat />
          ) : isMobile ? (<ChatButtonMobile />) : (<ChatButtonDesktop />)}
          {this.props.modal.status && <Modal config={this.props.config} />}
          <ModalImage />
        </Suspense>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  configLoaded: state.app.configLoaded,
  opened: state.app.opened,
  messages: state.messages,
  config: state.config,
  theme: state.theme,
  modal: state.app.modal,
});

const mapDispatchToProps = (dispatch) => ({
  loadConfig: () => dispatch(loadConfig()),
  toggleChat: () => dispatch(toggleChat(true)),
  toggleWebsocket: () => dispatch(toggleWebsocket(true)),
  initConversation: (config) => dispatch(initConversation(config)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
